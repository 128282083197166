import React, { useEffect, useRef, useContext } from "react";
import gsap from "gsap";
import styled from "styled-components";

import { AppContext, slideActions } from "../App";

const TimelineContainer = styled.div`
  display: flex;
  position: absolute;
  top: 2.4rem;
  height: 5.6rem;
  max-width: 56vw;
  margin: 0 auto;
  left: 0;
  right: 0;
  z-index: 24;
  opacity: 0;
  transition: opacity ease-in-out 0.32s;

  &:hover {
    opacity: 1;
  }
`;

const ProgressContainer = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 5.6rem;
  padding: 0 3.2rem;
  background-color: var(--bg-transparent-dark);
  border-radius: 2.8rem;
`;

const Progress = styled.input`
  outline: none;
  -webkit-appearance: none;
  background-color: #c4c4c4;
  height: 0.2rem;
  width: 100%;

  ::-webkit-slider-runnable-track {
    color: #00ff00;
  }

  ::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 0.8rem; /* Set a specific slider handle width */
    height: 0.8rem; /* Slider handle height */
    border-radius: 0.4rem; /* Slider handle height */
    background: white; /* Green background */
    cursor: pointer; /* Cursor on hover */
    transition: all ease-in-out 0.08s;
  }

  ::-moz-range-progress {
    background-color: #43e5f7;
  }

  ::-moz-range-thumb {
    width: 0.8rem; /* Set a specific slider handle width */
    height: 0.8rem; /* Slider handle height */
    height: 0.1rem; /* Slider handle height */
    background: white; /* Green background */
    cursor: pointer; /* Cursor on hover */
  }

  &:hover {
    ::-webkit-slider-thumb {
      width: 1.6rem; /* Set a specific slider handle width */
      height: 1.6rem; /* Slider handle height */
      border-radius: 0.8rem; /* Slider handle height */
    }
  }
`;

const PageIndicator = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 3.2rem;
  font-weight: 200;
  border-radius: 2.8rem;
  height: 5.6rem;
  width: 5.6rem;
  background-color: var(--bg-transparent-dark);
  color: var(--color-light);
  margin: 0 0 0 1.6rem;
  flex: 0 0 auto;
`;

let _timeout = null;

const Timeline = () => {
  const { state, dispatch } = useContext(AppContext);
  const inputRef = useRef(null);
  const timelineRef = useRef(null);
  // const tl = useRef(gsap.timeline());

  const show = () => {
    const target = timelineRef.current;
    gsap.to(target, { opacity: 1, y: 0, duration: 0.32, scaleX: 1, scaleY: 1 });
  };

  const hide = () => {
    const target = timelineRef.current;
    gsap.to(target, {
      opacity: 0,
      y: -64,
      duration: 0.32,
      scaleX: 0.32,
      scaleY: 0.32
    });
  };

  const blur = () => {
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  const handleMouseMove = () => {
    clearTimeout(_timeout);

    show();

    _timeout = setTimeout(hide, 1000);
  };

  const handleMouseUp = () => {
    blur();
  };

  const handleKeydown = (e) => {
    let action = {};

    switch (e.key) {
      case "ArrowLeft":
        action = {
          type: slideActions.DECREMENTSLIDEINDEX,
          data: { loop: false }
        };
        dispatch(action);
        break;
      case "ArrowRight":
        action = {
          type: slideActions.INCREMENTSLIDEINDEX,
          data: { loop: false }
        };
        dispatch(action);
        break;
      case " ":
        action = {
          type: slideActions.INCREMENTSLIDEINDEX,
          data: { loop: false }
        };
        dispatch(action);
        break;
      default:
      // Do nothing
    }
  };

  // On Mount
  useEffect(() => {
    // window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("keydown", handleKeydown);

    return () => {
      // window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("keydown", handleKeydown);
    };
  });

  const length = state.slides.length;
  const total = length - 1 || 0;
  const width = length * 9.6 + "rem";

  if (total === -1) return null;

  return (
    <TimelineContainer ref={timelineRef} style={{ width: width }}>
      <ProgressContainer onMouseLeave={blur}>
        <Progress
          type="range"
          min="0"
          max={total}
          ref={inputRef}
          value={state.currentSlideIndex}
          onMouseUp={handleMouseUp}
          onChange={(e) => {
            const { value } = e.target;
            dispatch({
              type: slideActions.UPDATESLIDEINDEX,
              data: parseInt(value, 10)
            });
          }}
        />
      </ProgressContainer>
      <PageIndicator>{state.currentSlideIndex + 1}</PageIndicator>
    </TimelineContainer>
  );
};

export default Timeline;
