import styled from "styled-components";

const H1 = styled.h1`
  display: inline-block;
  color: var(--color-accent-r);
  font-size: calc(4rem + 24vw);
  line-height: 1em;
  font-weight: 700;
  padding: 0 0 4.8rem 0;
  word-wrap: break-word;
  white-space: normal;
`;

const H2 = styled.h2`
  display: block;
  font-size: calc(14.4rem + 2.4vw);
  line-height: 1em;
  font-weight: 300;
  /* padding: 0 0 2.4rem 0; */
  color: var(--txt-dark);
`;

const H2Alt = styled(H2)`
  color: var(--txt-light);
`;

const H3 = styled.h3`
  display: block;
  font-size: 7.2rem;
  line-height: 1em;
  color: var(--txt-dark);
  margin: 0 0 0.64em 0;
`;

const LargeBody = styled.p`
  display: inline-block;
  font-size: 7.2rem;
  line-height: 1.2em;
  color: var(--color-accent-r);
  max-width: 144rem;
`;

const Body = styled.p`
  display: block;
  font-size: 3.2rem;
  line-height: 1.4em;
  /* font-size: calc(1.4rem + 0.1vw); */
  font-weight: 300;
  color: var(--txt-light);
  margin: 0 0 1.2rem 0;
`;

const Label = styled.label`
  font-size: 1rem;
  text-transform: uppercase;
`;

const Fineprint = styled.p`
  display: inline-block;
  font-size: 1.4rem;
  line-height: 1.5em;
  /* font-size: calc(1.4rem + 0.1vw); */
  font-weight: 500;
  color: var(--txt-light);
`;

const Caption = styled.caption`
  font-size: 1rem;
  font-family: var(--font-accent);
  color: var(--txt-dark);
`;

const List = styled.ul`
  list-style: initial;
`;

const ListItem = styled.li`
  font-size: 3.2rem;
  line-height: 1.4em;
  /* font-size: calc(1.4rem + 0.1vw); */
  font-weight: 300;
  color: var(--txt-light);
  margin: 0 0 1.2rem 0;
`;

export default Object.freeze({
  H1,
  H2,
  H2Alt,
  H3,
  Body,
  Label,
  Fineprint,
  List,
  ListItem,
  Caption
});

export {
  H1,
  H2,
  H2Alt,
  H3,
  LargeBody,
  Body,
  Label,
  Fineprint,
  ListItem,
  List,
  Caption
};
