import React from "react";
import styled from "styled-components";

const SliderWrapper = styled.section`
  box-sizing: border-box;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
`;

const Slide = ({ children }) => {
  return <SliderWrapper>{children}</SliderWrapper>;
};

export default Slide;
