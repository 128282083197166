import React, { forwardRef } from "react";
import styled from "styled-components";
import { Grid } from "../base";
import Gallery from "../compound/Gallery";

import projectData from "./projectData";

const lerp = (a, b, t) => (1 - t) * b + t * b;
const clamp = (num, min, max) => {
  return num <= min ? min : num >= max ? max : num;
};

const objectToArray = (obj) => {
  return Object.values(obj);
};

const extractImages = (projects) => {
  return projects.reduce((acc, cur) => {
    return [...acc, cur.images[0]];
  }, []);
};

const StyledProjectContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const Projects = forwardRef(({ ...props }, ref) => {
  return (
    <Grid {...props} ref={ref} id="projects">
      <StyledProjectContainer>
        <Gallery images={extractImages(objectToArray(projectData))} />
      </StyledProjectContainer>
    </Grid>
  );
});

export default Projects;
