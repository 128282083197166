import React from "react";
import styled from "styled-components";

import { Body, H3, Grid } from "../base";
import curiculumData from "./curiculumData";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Role = styled(Body)`
  color: var(--txt-default);
`;

const Duration = styled(Body)`
  /* color: var(--txt-default); */
`;

const Entry = styled.div`
  background-color: var(--bg-dark);
  padding: 2.4vh;
  margin: 0.2rem;
  border-radius: 2rem;
  flex: 0 0 autl;

  > * {
    margin: 0rem;
  }
`;

const CV = () => {
  return (
    <Container>
      {curiculumData.map((entry) => (
        <Entry key={entry.uuid}>
          <Role>
            {entry.role.value} at {entry.company.value}
          </Role>
          <Duration>
            ⬤ {entry.startAt.value} -{" "}
            {entry.endAt === null ? "present" : entry.endAt.value}
          </Duration>
        </Entry>
      ))}
    </Container>
  );
};

export default CV;
