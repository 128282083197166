import React, { useContext } from "react";

import { AppContext } from "../App";
import Slide from "./Slide";
import Gallery from "./Gallery";
import InteractiveGrid from "./InteractiveGrid";
import Video from "./Video";
import BlackLion from "../blacklion";
import Projects from "../projects";
import CV from "../cv";
import Base from "../base";
import { ByLetter, ByWord, FadeIn, Grow, SlideIn } from "./Animation";

import { uuidv4 } from "../Utils";

const componentLOT = {
  ...Base,
  Video,
  BlackLion,
  CV,
  Projects,
  Gallery,
  InteractiveGrid
};

const animationLOT = {
  ByWord,
  ByLetter,
  FadeIn,
  Grow
};

const getComponent = (type) => {
  return componentLOT[type];
};

const getAnimation = (type) => {
  return animationLOT[type];
};

const wrapComponent = (Component, Wrapper) => {
  return ({ children }) => (
    <Wrapper>
      <Component>{children}</Component>
    </Wrapper>
  );
};

const addAnimation = (component, Animation = SlideIn) => {
  return ({ children }) => (
    <Animation component={component}>{children}</Animation>
  );
};

const defaultRenderObject = () => ({
  animation: null,
  component: null,
  value: null,
  children: null,
  uuid: uuidv4()
});

const createRenderObject = (data) => {
  if (typeof data === "string") {
    return {
      ...defaultRenderObject(),
      component: data
    };
  }

  return { ...defaultRenderObject(), ...data };
};

const SlideNode = ({ data, ...props }) => {
  // Normalize Object
  const {
    component: componentType,
    animation: animationType,
    value,
    children
  } = createRenderObject(data);

  const Component = getComponent(componentType);
  const Animation = getAnimation(animationType);

  if (Component) {
    let Output = Component;

    if (Animation) {
      // if (typeof value === "string") {
      //   Output = wrapComponent(Animation, Component);
      Output = addAnimation(Component, Animation);
      // } else {
      //   Output = addAnimation(Component);
      // }
    }

    if (children) {
      return (
        <Output>
          {value}
          {children &&
            children.map((child, idx) => (
              <SlideNode key={child.uuid} {...props} data={child} />
            ))}
        </Output>
      );
    }

    return <Output {...props}>{value}</Output>;
  }

  return (
    <>
      {value}
      {children &&
        children.map((child) => <SlideNode key={child.uuid} data={child} />)}
    </>
  );
};

const Viewer = () => {
  const { state } = useContext(AppContext);

  const currentSlide = state.slides[state.currentSlideIndex];

  if (!currentSlide) return null;

  return (
    <Slide>
      <SlideNode data={currentSlide} />
    </Slide>
  );
};

export default Viewer;
