export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

const _pos = [0, 0];
let _prevPos = null;

export const getMousePosition = (e, target) => {
  const { left, top } = target ? target.getBoundingClientRect() : e.target;
  let x = e.clientX - left;
  let y = e.clientY - top;

  // Overwrite with touchevents
  if (e.changedTouches) {
    x = e.changedTouches[0].pageX;
    y = e.changedTouches[0].pageY;
  }
  // _pos.set(x, y);
  _pos[0] = x;
  _pos[1] = y;

  if (!_prevPos) {
    _prevPos = [..._pos];
  }

  const delta = [_prevPos[0] - _pos[0], _prevPos[1] - _pos[1]];

  _prevPos = [..._pos];

  return { point: _pos, delta };
};

export const clamp = (min, max, value) => {
  return Math.max(min, Math.min(value, max));
};
