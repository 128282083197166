import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import styled from "styled-components";

import { getMousePosition, uuidv4 } from "../Utils";
import { getImages } from "../base/Image";
import { clamp } from "../Utils";

const Canvas = styled.canvas`
  background-color: transparent;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: "grab";
  cursor: -webkit-grab;
`;

async function fetchImages(data, cb = () => {}) {
  const images = await getImages(data);

  cb(images);
}

const createImageObject = (image, size = [256, 256]) => {
  return {
    type: "canvasImage",
    uuid: uuidv4(),
    size,
    image
  };
};

const squareGrid = (total) => {
  const rc = Math.round(Math.sqrt(total));
  const columns = rc;
  const rows = Math.ceil(total / rc);

  return { columns, rows };
};

const imageSize = [512, 512];

const grid = (() => {
  // Total Size
  let _size = [1, 1];
  let _zoom = 1;
  let _fn = squareGrid;

  let _itemSize = 1;
  let _rows = null;
  let _columns = null;
  let _gutter = [0, 0];

  const calc = (total, itemSize, gutter) => {
    // Calculate Boundaries
    const { columns, rows } = _fn(total);
    _size = [
      columns * (itemSize[0] + gutter[0]) - gutter[0],
      rows * (itemSize[1] + gutter[1]) - gutter[1]
    ];
    _itemSize = itemSize;
    _gutter = gutter;
    _columns = columns;
    _rows = rows;
  };

  const scale = (zoomFactor) => {
    _zoom = zoomFactor;
  };

  const getPositionFromIndex = (itemIndex) => {
    if (!_rows && !_columns) {
      return [0, 0];
    }

    // console.log(_rows, _columns);

    const column = itemIndex % _columns;
    const row = Math.floor(itemIndex / _columns);

    // console.log("index: ", itemIndex, "x: ", column, "y: ", row);

    return [
      column * (_itemSize[0] + _gutter[0]) * _zoom,
      row * (_itemSize[1] + _gutter[1]) * _zoom
    ];
  };

  const getCenter = () => {
    return [_size[0] / 2, _size[1] / 2];
  };

  const getSize = () => {
    const [width, height] = _size;
    return [width * _zoom, height * _zoom];
  };

  const fit = (viewport) => {
    return (viewport[0] / _size[0]) * 1.5;
  };

  return {
    calc,
    scale,
    getPositionFromIndex,
    getSize,
    getCenter,
    fit
  };
})();

const renderImage = (position = [0, 0], size = [0, 0], image, ctx) => {
  if (!image || !ctx) return;

  const [x, y] = position;
  const [width, height] = size;

  ctx.save();
  ctx.drawImage(image, x, y, width, height);
  ctx.restore();
};

const center = (viewport, grid) => {
  const [vw, vh] = viewport;
  const [w, h] = grid.getSize();

  const x = (vw - w) / 2;
  const y = (vh - h) / 2;

  return [x, y];
};

const ImageGrid = ({ children, images, ref, ...props }) => {
  const [loadedImages, setLoadedImages] = useState([]);
  const [canvasState, updateCanvasState] = useState({
    offset: [0, 0],
    zoom: 0.5,
    size: [0, 0]
  });

  const _canvas = useRef(null);
  const _ctx = useRef(null);

  const render = (state, images, ctx) => {
    if (!state || !images || !ctx) return;
    const { zoom, size, offset } = state;

    // Clear canvas
    ctx.clearRect(0, 0, size[0], size[1]);

    images.forEach((image, idx) => {
      const { size } = image;
      const position = grid.getPositionFromIndex(idx);
      const [x, y] = position;

      const relativePosition = [x - offset[0], y - offset[1]];
      const relativeSize = [size[0] * zoom, size[1] * zoom];

      renderImage(relativePosition, relativeSize, image.image, ctx);
    });
  };

  const handleMouseMove = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { delta } = getMousePosition(e, document.body);

    updateCanvasState((state) => ({
      ...state,
      offset: [state.offset[0] + delta[0], state.offset[1] + delta[1]]
    }));
  };
  const handleMouseUp = (e) => {
    window.removeEventListener("mousemove", handleMouseMove);
    window.removeEventListener("mouseup", handleMouseUp);

    if (_canvas.current) {
      _canvas.current.style.cursor = null;
    }
  };

  const handleMouseDown = (e) => {
    getMousePosition(e, document.body);
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    if (_canvas.current) {
      _canvas.current.style.cursor = "grabbing";
    }
  };

  const resize = () => {
    updateCanvasState((state) => {
      const size = [window.innerWidth, window.innerHeight];
      const [x, y] = center(size, grid);

      const nextState = {
        ...state,
        offset: [-x, -y],
        size
      };

      return nextState;
    });
  };

  const zoom = (e) => {
    updateCanvasState((state) => {
      const { zoom, offset, size } = state;

      const newZoom = clamp(0.0001, 100, zoom + e.deltaY * 0.0002);

      const delta = newZoom / zoom;

      const wh = size[0] / 2;
      const hh = size[1] / 2;

      const originX = (offset[0] + wh) * delta;
      const originY = (offset[1] + hh) * delta;

      grid.scale(newZoom);

      return {
        ...state,
        zoom: newZoom,
        offset: [originX - wh, originY - hh]
      };
    });
  };

  useEffect(() => {
    const imageArr = images || children;

    gsap.set(_canvas.current, { opacity: 0 });

    fetchImages(imageArr, (result) => {
      if (result.length === 0) return;

      const imageWidth = result[0].width;
      const imageHeight = result[0].height;

      // Done
      grid.calc(result.length, [imageWidth, imageHeight], [24, 24]);
      const zoom = grid.fit([window.innerWidth, window.innerHeight]);
      grid.scale(zoom);

      updateCanvasState((state) => ({ ...state, zoom }));

      const transfomredImages = result.map((i) =>
        createImageObject(i, imageSize)
      );

      setLoadedImages(transfomredImages);
    });

    window.addEventListener("mousedown", handleMouseDown);
    window.addEventListener("mousewheel", zoom);
    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
      window.removeEventListener("mousewheel", zoom);
      window.removeEventListener("resize", resize);
    };
  }, []);

  useEffect(() => {
    if (!_canvas.current) return;

    _ctx.current = _canvas.current.getContext("2d");
  }, [_canvas.current]);

  useEffect(() => {
    if (loadedImages.length === 0) return;
    resize();
    render(canvasState, loadedImages, _ctx.current);
    gsap.to(_canvas.current, { opacity: 1, duration: 0.8 });
  }, [loadedImages]);

  useEffect(() => {
    render(canvasState, loadedImages, _ctx.current);
  }, [canvasState]);

  const [width, height] = canvasState.size;

  return <Canvas {...props} ref={_canvas} width={width} height={height} />;
};

export default ImageGrid;
