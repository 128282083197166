import React, { useEffect } from "react";
import styled from "styled-components";
import gsap from "gsap";

import keyboardData from "./keyboardData";

// Keyboard
const StyledKeyboard = styled.div`
  width: 48rem;
  position: absolute;
  background: rgba(10, 10, 10, 0.88);
  padding: 0.2rem;
  border-radius: 0.6rem;
  transform: rotateX(90deg) translate3D(-50%, 24rem, -16rem);
  opacity: 0;
`;

const AmbientGlow = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0%;
  left: 0;
  background: linear-gradient(0, rgba(0, 0, 0, 0.32), rgba(99, 132, 73, 0.16));
`;

const StyledKeyboardRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const keyBaseSize = 3.2;

const KeyCaps = styled.span`
  box-sizing: border-box;
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 0.8rem;
  background: #222;
  width: 100%;
  height: 100%;
  padding: 1.2rem;
  border-radius: 0.3rem;
  color: white;
`;

const Key = styled.button`
  box-sizing: border-box;
  background: transparent;
  height: 3.2rem;
  margin: 0;
  padding: 0.12rem;
  border: none;
  cursor: pointer;
  transition: background-color ease-in-out 0.12s;

  &[data-size="1"] {
    text-transform: uppercase;
  }

  ${KeyCaps}:hover, ${KeyCaps}.active {
    background-color: var(--color-accent-r);
  }
`;

const remapKey = {
  Control: "Ctrl",
  Escape: "Capslock"
};

const KeyboardRow = ({ data }) => {
  const highlightKey = (e) => {
    const key = remapKey[e.key] ? remapKey[e.key] : e.key.toLowerCase();

    // Find primary key value
    const hasKeyValue =
      document.querySelectorAll(`button[data-key="${key}"]`).length > 0;

    if (hasKeyValue) {
      gsap.to(`[data-key="${key}"] > span`, {
        backgroundColor: `var(--color-accent-r)`,
        duration: 0.24,
        onComplete: () => {
          gsap.to(`[data-key="${key}"] > span`, {
            backgroundColor: `#222`,
            duration: 0.24
          });
        }
      });
      return;
    }

    // Find alt key value
    const hasAltKeyValue = document.querySelectorAll(
      `button[data-alt-key="${key}"]`
    );

    if (hasAltKeyValue) {
      gsap.to(`[data-alt-key="${key}"] > span`, {
        backgroundColor: `var(--color-accent-r)`,
        duration: 0.24,
        onComplete: () => {
          gsap.to(`[data-alt-key="${key}"] > span`, {
            backgroundColor: `#222`,
            duration: 0.24
          });
        }
      });
      return;
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", highlightKey);

    return () => {
      window.removeEventListener("keydown", highlightKey);
    };
  }, []);

  return (
    <StyledKeyboardRow>
      {data.map((key, idx) => (
        <Key
          key={key + idx}
          style={{ width: key.size * keyBaseSize + "rem" }}
          data-size={key.size}
          data-key={key.value}
          data-alt-key={key.altValue || ""}
          tabIndex={-1}
        >
          <KeyCaps>{key.value}</KeyCaps>
        </Key>
      ))}
    </StyledKeyboardRow>
  );
};

const Keyboard = (props) => {
  return (
    <StyledKeyboard {...props}>
      {Object.keys(keyboardData).map((key, idx) => (
        <KeyboardRow data={keyboardData[key]} key={key + idx} />
      ))}
      <AmbientGlow />
    </StyledKeyboard>
  );
};

export default Keyboard;
