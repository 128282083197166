import React, { createContext, useReducer } from "react";
import styled from "styled-components";
import "./style.css";

import { Header, Footer, Timeline, Menu, Viewer } from "./compound";
import slideData from "./slideData";

const StyledApp = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  position: relative;
  color: var(--txt-default);
  background-color: var(--bg-default);
  transition: var(--transition-default);
`;

export const generalActions = {
  TOGGLETHEME: "toggletheme",
  CHANGETHEME: "changetheme"
};

export const slideActions = {
  UPDATESLIDEINDEX: "updateslideindex",
  INCREMENTSLIDEINDEX: "incrementslideindex",
  DECREMENTSLIDEINDEX: "decrementslideindex"
};

const slideReducer = (state, action) => {
  let nextState = null;

  const length = state.slides.length;

  switch (action.type) {
    case slideActions.UPDATESLIDEINDEX:
      nextState = {
        ...state,
        currentSlideIndex: action.data
      };

      break;
    case slideActions.INCREMENTSLIDEINDEX:
      if (!action.data.loop && state.currentSlideIndex === length - 1) {
        return state;
      }

      nextState = {
        ...state,
        currentSlideIndex: (state.currentSlideIndex + 1) % length
      };

      break;
    case slideActions.DECREMENTSLIDEINDEX:
      if (!action.data.loop && state.currentSlideIndex === 0) {
        return state;
      }

      nextState = {
        ...state,
        currentSlideIndex:
          (state.currentSlideIndex === 0 ? length : state.currentSlideIndex) - 1
      };

      break;
    case generalActions.CHANGETHEME:
      nextState = {
        ...state,
        theme: (state.theme = action.data)
      };
      break;
    case generalActions.TOGGLETHEME:
      nextState = {
        ...state,
        theme: state.theme === "light" ? "dark" : "light"
      };
      break;
    default:
      nextState = { ...state };
  }

  return nextState;
};

export const AppContext = createContext();

export default function App() {
  const [state, dispatch] = useReducer(slideReducer, {
    slides: slideData,
    // Change this later
    currentSlideIndex: 0,
    theme: "dark"
  });

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <StyledApp id="app" data-theme={state.theme}>
        <Header />
        <Menu />
        <Timeline />
        <Viewer />
        <Footer />
      </StyledApp>
    </AppContext.Provider>
  );
}
