export const primitiveTypes = {
  STRING: "string",
  NUMBER: "number",
  VECTOR3: "vector3",
  ENUM: "enum",
  TIMESTAMP: "timestamp",
  BOX: "box",
  IMAGE: "image",
  VIDEO: "video"
};

const box = (value) => ({
  type: primitiveTypes.BOX,
  value: value || [0, 0, 0, 0]
});

const string = (value) => ({
  type: primitiveTypes.STRING,
  value: value || "string"
});

const vector3 = (value) => ({
  type: primitiveTypes.VECTOR3,
  value: value || { x: 0, y: 0, z: 0 }
});

const number = (value) => ({
  type: primitiveTypes.NUMBER,
  value: value || 0
});

const enumerable = (value) => ({
  type: primitiveTypes.ENUM,
  value: value || { optionA: "Option A", optionB: "Option B" }
});

const timestamp = () => ({
  type: primitiveTypes.TIMESTAMP,
  value: new Date().toString()
});

const image = (value) => ({
  type: primitiveTypes.IMAGE,
  uri: value.uri || "",
  alt: value.alt || "",
  caption: value.caption || ""
});

const video = (value) => ({
  type: primitiveTypes.VIDEO,
  uri: value.uri || ""
});

const PrimitiveTypes = Object.freeze({
  box,
  string,
  vector3,
  number,
  enumerable,
  timestamp,
  image,
  video
});

export default PrimitiveTypes;

export { box, string, vector3, number, enumerable, timestamp, image, video };
