import { string } from "../data";
import { uuidv4 } from "../Utils";

const data = [
  {
    uuid: uuidv4(),
    startAt: string("Nov, 2020"),
    endAt: null,
    company: string("FitXR"),
    role: string("Product Design Manager")
  },
  {
    uuid: uuidv4(),
    startAt: string("Sept, 2019"),
    endAt: string("Oct 2020"),
    company: string("Fnatic Ltd"),
    role: string("Frontend Developer")
  },
  {
    uuid: uuidv4(),
    startAt: string("May, 2017"),
    endAt: string("May 2019"),
    company: string("Facebook Inc."),
    role: string("UX Engineer & AR/VR Product Designer")
  },
  {
    uuid: uuidv4(),
    startAt: string("Aug, 2016"),
    endAt: string("Mar 2017"),
    company: string("Google (Contractor)"),
    role: string("Interaction Designer")
  },
  {
    uuid: uuidv4(),
    startAt: string("Apr, 2014"),
    endAt: string("Oct 2015"),
    company: string("Fantasy Interactive"),
    role: string("Designer")
  },
  {
    uuid: uuidv4(),
    startAt: string("Jan, 2010"),
    endAt: string("Dec 2016"),
    company: string("Freelancer"),
    role: string("Designer & Developer")
  }
];

export default data;
