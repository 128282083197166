import styled from "styled-components";

const Base = styled.button`
  font-size: 1.4rem;
  outline: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 4.8rem;
  border: none;
  border-radius: 2.4rem;
  padding: 0 2.4rem;
  margin: 1.2rem;
  cursor: pointer;
`;

const Primary = styled(Base)`
  color: var(--color-light);
  background-color: var(--color-accent-r);
`;

export default Object.freeze({ Primary });
