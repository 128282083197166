import Button from "./Button";
import Grid, { Split } from "./Grid";
import Image from "./Image";
import { SpaceSingle, SpaceDouble } from "./Spacing";

import {
  H1,
  H2,
  H2Alt,
  H3,
  LargeBody,
  Body,
  Label,
  Fineprint,
  List,
  ListItem,
  Caption
} from "./Typography";

export default Object.freeze({
  Button,
  H1,
  H2,
  H2Alt,
  H3,
  LargeBody,
  Body,
  Label,
  Image,
  Grid,
  Split,
  Fineprint,
  List,
  ListItem,
  SpaceSingle,
  SpaceDouble,
  Caption
});
export {
  Button,
  H1,
  H2,
  H2Alt,
  H3,
  LargeBody,
  Body,
  Label,
  Image,
  Grid,
  Fineprint,
  Split,
  List,
  ListItem,
  SpaceSingle,
  SpaceDouble,
  Caption
};
