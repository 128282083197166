import React from "react";
import styled from "styled-components";

import Widget from "./Widget";

const StyledSpotify = styled(Widget)`
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  width: 32rem;
  height: 8.8rem;
  background: linear-gradient(-45deg, #111, #121212);
  border-radius: 0.6rem;
  top: 50%;
  left: 50%;
  box-shadow: 2.4rem 1.2rem 4.8rem 2.4rem rgba(0, 0, 0, 0.24);
`;

const AlbumCover = styled.div`
  width: 7.2rem;
  height: 7.2rem;
  margin: 0.8rem;
  background: #333;
  background-image: url("./assets/images/os/album-art.jpg");
  border-radius: 0.32rem;
  flex: 0 0 auto;
`;

const Meta = styled.div`
  box-sizing: border-box;
  flex: 1 1 auto;
  padding: 0 1.6rem 0 0.8rem;
`;

const Title = styled.h1`
  font-size: 1rem;
  margin: 0 0 0.6rem 0;
  color: white;
`;

const Artist = styled.h2`
  font-size: 0.6rem;
  color: #7b7b7b;
`;

const Controls = styled.div`
  width: 100%;
`;

const ProgressBar = styled.input`
  margin: 0.8rem 0 0 0;
  overflow: hidden;
  outline: none;
  -webkit-appearance: none;
  background-color: #c4c4c4;
  height: 0.2rem;
  width: 100%;

  ::-webkit-slider-runnable-track {
    height: 0.2rem;
    -webkit-appearance: none;
    appearance: none;
    color: var(--color-accent-r);
  }

  ::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 0.4rem; /* Set a specific slider handle width */
    height: 0.2rem; /* Slider handle height */
    background: white; /* Green background */
    /* background-color: var(--color-accent-r); */
    cursor: pointer; /* Cursor on hover */
    transform: translate(0, -48%);
    box-shadow: -400px 0 0 400px var(--color-accent-r);
    transition: all ease-in-out 0.08s;
  }

  ::-moz-range-progress {
    height: 0.2rem;
    -webkit-appearance: none;
    color: var(--color-accent-r);
  }

  ::-moz-range-thumb {
    width: 0.8rem; /* Set a specific slider handle width */
    height: 0.8rem; /* Slider handle height */
    height: 0.1rem; /* Slider handle height */
    background: white; /* Green background */
    cursor: pointer; /* Cursor on hover */
  }

  &:hover {
    ::-webkit-slider-thumb {
      width: 0.8rem; /* Set a specific slider handle width */
      height: 0.8rem; /* Slider handle height */
      border-radius: 0.4rem; /* Slider handle height */
    }
  }
`;

const Timestamps = styled.div`
  margin: 1.2rem 0 0 0;
  display: flex;
  justify-content: space-between;
  font-size: 0.6rem;
  color: #7b7b7b;
`;

const Spotify = ({ children, ...props }) => {
  return (
    <StyledSpotify {...props}>
      <AlbumCover />
      <Meta>
        <Title>Dust on the Ground</Title>
        <Artist>Bombay Bicycle Club</Artist>
        <Controls>
          <Timestamps>
            <span>2:34</span>
            <span>1:58</span>
          </Timestamps>
          <ProgressBar type="range" />
        </Controls>
      </Meta>
      {children}
    </StyledSpotify>
  );
};

export default Spotify;
