import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";

const Handle = styled.div`
  width: 8%;
  height: 0.2rem;
  border-radius: 0.1rem;
  position: absolute;
  bottom: -2.4rem;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: rgba(255, 255, 255, 0.48);
  cursor: pointer;

  &:hover {
    background-color: var(--color-accent-r);
  }
`;

const _pos = [0, 0];
let _prevPos = null;

const getMousePosition = (e, target) => {
  const { left, top } = target ? target.getBoundingClientRect() : e.target;
  let x = e.clientX - left;
  let y = e.clientY - top;

  // Overwrite with touchevents
  if (e.changedTouches) {
    x = e.changedTouches[0].pageX;
    y = e.changedTouches[0].pageY;
  }
  // _pos.set(x, y);
  _pos[0] = x;
  _pos[1] = y;

  if (!_prevPos) {
    _prevPos = [..._pos];
  }

  const delta = [_prevPos[0] - _pos[0], _prevPos[1] - _pos[1]];

  _prevPos = [..._pos];

  return { point: _pos, delta };
};

const offsetToProperty = (offset) => {
  if (!Array.isArray(offset) || offset.length !== 3) return "";

  const [x, y, z] = offset;
  return { transform: `translate3d(${x}px, ${y}px, ${z}px` };
};

// x' = x cos(angle) + y sin(angle)
// y' = -x sin(angle) + y cos(angle)
const transformDirection = (dir, angle) => {
  const [x, y] = dir;
  const rad = (angle / 180) * Math.PI;

  const cos = Math.cos(rad);
  const sin = Math.sin(rad);

  const _x = x * cos + y * sin;
  const _y = -x * sin + y + cos;

  return [_x, _y];
};

const Draggable = ({
  component: Component,
  children,
  x = 0,
  y = 0,
  z = 0,
  ...restProps
}) => {
  const [offset, setOffset] = useState([x, y, z]);
  const draggableRef = useRef(null);

  const handleMouseMove = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { delta } = getMousePosition(e, document.body);

    // const d = transformDirection(delta, 45);
    const d = delta;

    setOffset((state) => {
      return [state[0] - d[0], state[1] - d[1], state[2]];
    });
  };
  const handleMouseUp = (e) => {
    window.removeEventListener("mousemove", handleMouseMove);
    window.removeEventListener("mouseup", handleMouseUp);
  };

  const handleMouseDown = (e) => {
    getMousePosition(e, document.body);
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };

  useEffect(() => {
    if (!draggableRef.current) return;
    draggableRef.current.addEventListener("mousedown", handleMouseDown);

    return () => {
      if (!draggableRef.current) return;
      draggableRef.current.removeEventListener("mousedown", handleMouseDown);
    };
  }, [draggableRef.current]);

  return (
    <Component
      {...restProps}
      style={{
        ...offsetToProperty(offset),
        position: "absolute",
        zIndex: 24
      }}
    >
      {children}
      <Handle ref={draggableRef} />
    </Component>
  );
};

const makeDraggable = (Component) => {
  return ({ children, ...restProps }) => (
    <Draggable {...restProps} component={Component}>
      {children}
    </Draggable>
  );
};

export default Draggable;

export { makeDraggable };
