import React, { useEffect, useState } from "react";
import styled from "styled-components";
import gsap from "gsap";

const StyledVideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: black;
`;

const StyledVideo = styled.video`
  opacity: 0;
`;

const Video = ({ children, ...props }) => {
  const [video, setVideo] = useState(null);

  useEffect(() => {
    var req = new XMLHttpRequest();
    req.open("GET", children, true);
    req.responseType = "blob";

    req.onload = function () {
      // Onload is triggered even on 404
      // so we need to check the status code
      if (this.status === 200) {
        var videoBlob = this.response;
        var vid = URL.createObjectURL(videoBlob); // IE10+
        // Video is now downloaded
        // and we can set it as source on the video element
        setVideo(vid);
      }
    };
    req.onerror = function () {
      // Error
    };

    req.send();
  }, []);

  useEffect(() => {
    if (!video) return;
    gsap.to("#video", {
      opacity: 1,
      duration: 1,
      delay: 0.32,
      onComplete: () => {
        const v = document.getElementById("video");
        if (v) {
          v.play();
        }
      }
    });
  }, [video]);

  if (!video) return null;

  return (
    <StyledVideoContainer {...props}>
      <StyledVideo id="video" width="80%" controls>
        <source src={video} type="video/mp4" />
      </StyledVideo>
    </StyledVideoContainer>
  );
};

export default Video;
