import React from "react";
import styled from "styled-components";

const Space = styled.span`
  display: block;
  width: 100%;
`;

const SpaceSingle = styled(Space)`
  height: 2.4rem;
`;

const SpaceDouble = styled(Space)`
  height: 4.8rem;
`;

export { SpaceSingle, SpaceDouble };
