import React, { useLayoutEffect, useRef, createRef } from "react";
import styled from "styled-components";
import gsap from "gsap";

import { uuidv4 } from "../Utils";

function generateUID() {
  // I generate the UID from two parts here
  // to ensure the random number provide enough bits.
  var firstPart = (Math.random() * 46656) | 0;
  var secondPart = (Math.random() * 46656) | 0;
  firstPart = ("000" + firstPart.toString(36)).slice(-3);
  secondPart = ("000" + secondPart.toString(36)).slice(-3);
  return firstPart + secondPart;
}

export const createAnimationObject = (params) => {
  // Create a normalized animation object
};

const AnimationObject = styled.span`
  display: inline-block;
`;

const StyledLetter = styled(AnimationObject)`
  display: inline-block;
  white-space: pre;
`;

const StyledWord = styled(AnimationObject)`
  display: inline-block;
  white-space: pre;
`;

const mapByLetter = (input) => {
  if (typeof input !== "string") return [input];

  return input.split("");
};

const mapByWord = (input) => {
  if (typeof input !== "string") return [input];

  return input.split(" ").map((str) => `${str} `);
};

// Children have the same animation
const Base = ({ children, from, to, component: Component }) => {
  const uid = useRef(generateUID());
  const ref = createRef();

  useLayoutEffect(() => {
    // gsap.set(`.animation-${uid.current}`, from);
    // gsap.to(`.animation-${uid.current}`, to);

    gsap.set(ref.current, from);
    const animation = gsap.to(ref.current, to);

    return () => {
      animation.kill();
    };
  }, []);

  return (
    <Component className={`animation-${uid.current}`} ref={ref}>
      {children}
    </Component>
  );
};

const SlideIn = ({ children, ...props }) => {
  const from = { opacity: 0, y: -12 };
  const to = { opacity: 1, y: 0 };

  return (
    <Base {...props} from={from} to={to}>
      {children}
    </Base>
  );
};

const Grow = ({ children, ...props }) => {
  return (
    <Base
      {...props}
      from={{ opacity: 0, scaleX: 1.1, scaleY: 1.1 }}
      to={{ opacity: 1, scaleX: 1, scaleY: 1, duration: 0.32 }}
    >
      {children}
    </Base>
  );
};

const FadeIn = ({ children, ...props }) => {
  return (
    <Base
      {...props}
      from={{ opacity: 0 }}
      to={{
        duration: 0.64,
        opacity: 1,
        ease: "power1.inOut"
      }}
    >
      {children}
    </Base>
  );
};

const ByLetter = ({ children, component: Component, ...props }) => {
  useLayoutEffect(() => {
    gsap.set(".letter", { opacity: 0, y: "-24px" });
    gsap.to(".letter", {
      duration: 0.24,
      opacity: 1,
      y: 0,
      ease: "power1.inOut",
      stagger: 0.16
    });
  }, [children]);

  if (typeof children !== "string") return <>{children}</>;

  return (
    <Component {...props}>
      {children.split("").map((letter, idx) => (
        <StyledLetter className="letter" key={letter + idx}>
          {letter}
        </StyledLetter>
      ))}
    </Component>
  );
};

const ByWord = ({ children, component: Component, ...props }) => {
  useLayoutEffect(() => {
    gsap.set(".word", { opacity: 0, y: "-24px" });
    gsap.to(".word", {
      duration: 0.24,
      opacity: 1,
      y: 0,
      ease: "power1.inOut",
      stagger: 0.16
    });
  }, [children]);

  if (typeof children !== "string") return <>{children}</>;

  return (
    <Component {...props}>
      {children.split(" ").map((word, idx) => (
        <StyledWord className="word" key={word + idx}>
          {word + " "}
        </StyledWord>
      ))}
    </Component>
  );
};

export default Object.freeze({
  ByLetter,
  ByWord,
  FadeIn,
  Grow,
  SlideIn
});

export { ByLetter, ByWord, FadeIn, Grow, SlideIn };
