import { string, image } from "../data";
import { uuidv4 } from "../Utils";

const projectData = {
  ME: {
    uuid: uuidv4(),
    displayName: string("Personal Site"),
    copy: string(""),
    releasedAt: string("Year 2021"),
    url: string(""),
    images: [
      image({
        uri: "./assets/images/projects/playful.png",
        alt: "Main hero image"
      })
    ]
  },
  ICONGRID: {
    uuid: uuidv4(),
    displayName: string("Icongrid."),
    copy: string(""),
    releasedAt: string("Year 2021"),
    url: string(""),
    images: [
      image({
        uri: "./assets/images/projects/icongrid.jpg",
        alt: "Main hero image"
      })
    ]
  },
  HUAWEI: {
    uuid: uuidv4(),
    displayName: string("Huawei OS - Concepts"),
    copy: string(""),
    releasedAt: string("Year 2021"),
    url: string(""),
    images: [
      image({
        uri: "./assets/images/projects/huawei.jpg",
        alt: "Main hero image"
      })
    ]
  }
};

export default projectData;
