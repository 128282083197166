import styled from "styled-components";

const Grid = styled.div`
  /* box-sizing: border-box; */
  /* width: 100%; */
  /* display: grid; grid-template-columns: repeat(12, 1fr); */
  box-sizing: border-box;
  padding: 6.4rem;
`;

const Split = styled(Grid)`
  display: flex;
  width: 100vw;
  max-width: 120vh;

  /* :first-child() {
    grid-column: 1;
  }

  :last-child() {
    grid-column: 2;
  } */

  > :nth-child(odd) {
    margin-right: 2.4rem;
  }
`;

export default Grid;

export { Split };
