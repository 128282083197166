import { image } from "./data";
import { uuidv4 } from "./Utils";

const data = [
  {
    component: "Grid",
    uuid: uuidv4(),
    children: [
      {
        type: "string",
        value: "Hello",
        uuid: uuidv4(),
        component: "H1",
        animation: "Grow"
      }
    ]
  },
  {
    component: "Grid",
    uuid: uuidv4(),
    children: [
      {
        type: "string",
        uuid: uuidv4(),
        value: "I am Bojan",
        component: "LargeBody",
        animation: "FadeIn"
      }
    ]
  },
  {
    component: "Grid",
    uuid: uuidv4(),
    children: [
      {
        type: "string",
        uuid: uuidv4(),
        value:
          "I am a London-based Designer and Developer with a passion for connecting design, art, and technology.",
        component: "LargeBody",
        animation: "FadeIn"
      }
    ]
  },
  {
    component: "Grid",
    uuid: uuidv4(),
    children: [
      {
        type: "string",
        uuid: uuidv4(),
        value: "Code <-> Design",
        component: "H2",
        animation: "FadeIn"
      },
      {
        component: "SpaceDouble"
      },
      {
        type: "string",
        uuid: uuidv4(),
        value:
          "Algorithms ⬤ Design Patterns ⬤ Interactions ⬤ Design Systems ⬤ Simulations ⬤ Grids ⬤ Building Tools",
        component: "Body",
        animation: "FadeIn"
      }
    ]
  },
  {
    component: "Grid",
    uuid: uuidv4(),
    children: [
      {
        type: "string",
        uuid: uuidv4(),
        value: "Follow along",
        component: "Body",
        animation: "FadeIn"
      },
      {
        type: "string",
        uuid: uuidv4(),
        value: "csb-0tx3r.netlify.app",
        component: "H3",
        animation: "FadeIn"
      }
    ]
  },
  {
    component: "Grid",
    animation: "FadeIn",
    children: [
      {
        component: "CV",
        uuid: uuidv4()
      }
    ]
  },
  {
    component: "Grid",
    uuid: uuidv4(),
    children: [
      {
        type: "string",
        uuid: uuidv4(),
        value: "Inspiration comes from everywhere.",
        animation: "FadeIn",
        component: "H3"
      }
    ]
  },
  {
    component: "InteractiveGrid",
    uuid: uuidv4(),
    value: [
      image({ uri: "./assets/images/inspiration/inspo-1.jpg", alt: "" }),
      image({ uri: "./assets/images/inspiration/inspo-2.jpg", alt: "" }),
      image({ uri: "./assets/images/inspiration/inspo-3.jpg", alt: "" }),
      image({ uri: "./assets/images/inspiration/inspo-4.jpg", alt: "" }),
      image({ uri: "./assets/images/inspiration/inspo-5.jpg", alt: "" }),
      image({ uri: "./assets/images/inspiration/inspo-6.jpg", alt: "" }),
      image({ uri: "./assets/images/inspiration/inspo-7.jpg", alt: "" }),
      image({ uri: "./assets/images/inspiration/inspo-8.jpg", alt: "" }),
      image({ uri: "./assets/images/inspiration/inspo-9.jpg", alt: "" }),
      image({ uri: "./assets/images/inspiration/inspo-10.jpg", alt: "" }),
      image({ uri: "./assets/images/inspiration/inspo-11.jpg", alt: "" }),
      image({ uri: "./assets/images/inspiration/inspo-12.jpg", alt: "" }),
      image({ uri: "./assets/images/inspiration/inspo-13.jpg", alt: "" }),
      image({ uri: "./assets/images/inspiration/inspo-14.jpg", alt: "" }),
      image({ uri: "./assets/images/inspiration/inspo-15.jpg", alt: "" }),
      image({ uri: "./assets/images/inspiration/inspo-16.jpg", alt: "" }),
      image({ uri: "./assets/images/inspiration/inspo-17.jpg", alt: "" }),
      image({ uri: "./assets/images/inspiration/inspo-18.jpg", alt: "" }),
      image({ uri: "./assets/images/inspiration/inspo-19.jpg", alt: "" }),
      image({ uri: "./assets/images/inspiration/inspo-20.jpg", alt: "" }),
      image({ uri: "./assets/images/inspiration/inspo-21.jpg", alt: "" }),
      image({ uri: "./assets/images/inspiration/inspo-22.jpg", alt: "" }),
      image({ uri: "./assets/images/inspiration/inspo-23.jpg", alt: "" }),
      image({ uri: "./assets/images/inspiration/inspo-24.jpg", alt: "" }),
      image({ uri: "./assets/images/inspiration/inspo-25.jpg", alt: "" })
    ]
  },
  {
    component: "Grid",
    uuid: uuidv4(),
    children: [
      {
        type: "string",
        uuid: uuidv4(),
        value: "My Process",
        animation: "FadeIn",
        component: "H2"
      },
      {
        uuid: uuidv4(),
        value: "",
        component: "SpaceSingle"
      },
      {
        type: "string",
        uuid: uuidv4(),
        value: "Discovery -> Analysis -> Design -> Evaluation",
        animation: "FadeIn",
        component: "Body"
      }
    ]
  },
  {
    component: "Grid",
    uuid: uuidv4(),
    animation: "FadeIn",
    children: [
      {
        uuid: uuidv4(),
        value: [
          image({ uri: "./assets/images/process/process-1.jpg", alt: "" }),
          image({ uri: "./assets/images/process/process-2.png", alt: "" }),
          image({ uri: "./assets/images/process/process-3.jpg", alt: "" }),
          image({ uri: "./assets/images/process/process-4.png", alt: "" }),
          image({ uri: "./assets/images/projects/huawei.jpg", alt: "" })
        ],
        component: "Gallery"
      }
    ]
  },
  {
    component: "Grid",
    uuid: uuidv4(),
    children: [
      {
        type: "string",
        uuid: uuidv4(),
        value: "Project",
        animation: "FadeIn",
        component: "H2"
      },
      {
        type: "string",
        uuid: uuidv4(),
        value: "The Weather Channel",
        animation: "FadeIn",
        component: "H2Alt"
      }
    ]
  },
  {
    component: "Grid",
    uuid: uuidv4(),
    animation: "FadeIn",
    children: [
      {
        uuid: uuidv4(),
        value: [
          image({ uri: "./assets/images/projects/twc/twc_1.jpg", alt: "" }),
          image({ uri: "./assets/images/projects/twc/twc_2.jpg", alt: "" }),
          image({ uri: "./assets/images/projects/twc/twc_3.jpg", alt: "" }),
          image({ uri: "./assets/images/projects/twc/twc_3-1.jpg", alt: "" }),
          image({ uri: "./assets/images/projects/twc/twc_4.jpg", alt: "" }),
          image({ uri: "./assets/images/projects/twc/twc_5.jpg", alt: "" }),
          image({ uri: "./assets/images/projects/twc/twc_6.jpg", alt: "" }),
          image({ uri: "./assets/images/projects/twc/twc_7.jpg", alt: "" }),
          image({ uri: "./assets/images/projects/twc/twc_8.jpg", alt: "" }),
          image({ uri: "./assets/images/projects/twc/twc_9.jpg", alt: "" })
        ],
        component: "Gallery"
      }
    ]
  },
  {
    component: "Grid",
    uuid: uuidv4(),
    children: [
      {
        type: "string",
        uuid: uuidv4(),
        value: "Project",
        animation: "FadeIn",
        component: "H2"
      },
      {
        type: "string",
        uuid: uuidv4(),
        value: "Black Lion",
        animation: "FadeIn",
        component: "H2Alt"
      }
    ]
  },
  {
    component: "Grid",
    uuid: uuidv4(),
    animation: "FadeIn",
    children: [
      {
        type: "string",
        uuid: uuidv4(),
        value: "Where does the name come from?",
        component: "H3"
      }
    ]
  },
  {
    component: "Grid",
    uuid: uuidv4(),
    animation: "FadeIn",
    children: [
      {
        type: "string",
        uuid: uuidv4(),
        value: image({
          uri: "./assets/images/blacklion.jpg",
          alt: "Black Lion",
          caption: "295 W End Ln, London NW6 1LG"
        }),
        component: "Image"
      }
    ]
  },
  {
    component: "Grid",
    uuid: uuidv4(),
    animation: "FadeIn",
    children: [
      {
        type: "string",
        uuid: uuidv4(),
        value: "What were we trying to solve?",
        component: "H3"
      },
      {
        type: "string",
        uuid: uuidv4(),
        value:
          "Interactions in VR and AR are hard. Virtual keyboards for VR and AR add additional friction, similar to Smart TV controls etc. Voice assistants/commands are valuable options, but not suitable for every situation. It is comparable to the digital/virtual keyboard seen on smart devices/OS such as iOS and Android.",
        component: "Body"
      }
    ]
  },
  {
    component: "Grid",
    uuid: uuidv4(),
    animation: "FadeIn",
    children: [
      {
        value: "Goals",
        uuid: uuidv4(),
        component: "H3"
      },
      {
        component: "List",
        uuid: uuidv4(),
        children: [
          {
            type: "string",
            uuid: uuidv4(),
            value: "Create an OS like default interaction for inputs for VR/AR",
            component: "ListItem",
            animation: "FadeIn"
          },
          {
            type: "string",
            uuid: uuidv4(),
            value: "Create a technical proof of concept",
            component: "ListItem",
            animation: "FadeIn"
          },
          {
            type: "string",
            uuid: uuidv4(),
            value: "Show interaction and user interface concepts",
            component: "ListItem",
            animation: "FadeIn"
          }
        ]
      }
    ]
  },
  {
    component: "Grid",
    uuid: uuidv4(),
    animation: "FadeIn",
    children: [
      {
        type: "string",
        uuid: uuidv4(),
        value: "Requierments",
        component: "H3"
      },
      {
        component: "List",
        uuid: uuidv4(),
        children: [
          {
            type: "string",
            uuid: uuidv4(),
            value: "Can be extended with other peripherals easily",
            component: "ListItem",
            animation: "FadeIn"
          },
          {
            type: "string",
            uuid: uuidv4(),
            value:
              "Resilient under different conditions e.g lighting, reflective surfaces",
            component: "ListItem",
            animation: "FadeIn"
          },
          {
            type: "string",
            uuid: uuidv4(),
            value: "Reality agnostic AR, VR, and MR",
            component: "ListItem",
            animation: "FadeIn"
          },
          {
            type: "string",
            uuid: uuidv4(),
            value: "Can be done by a very small team on the site",
            component: "ListItem",
            animation: "FadeIn"
          }
        ]
      }
    ]
  },
  {
    component: "Grid",
    uuid: uuidv4(),
    animation: "FadeIn",
    children: [
      {
        type: "string",
        uuid: uuidv4(),
        value: "Definition of Success",
        component: "H3"
      },
      {
        component: "List",
        uuid: uuidv4(),
        children: [
          {
            type: "string",
            uuid: uuidv4(),
            value: "Build a working proof of concept",
            component: "ListItem",
            animation: "FadeIn"
          },
          {
            type: "string",
            uuid: uuidv4(),
            value:
              "Pitch this idea to senior management and build a team around it",
            component: "ListItem",
            animation: "FadeIn"
          },
          {
            type: "string",
            uuid: uuidv4(),
            value: "Applying for an interaction patent",
            component: "ListItem",
            animation: "FadeIn"
          }
        ]
      }
    ]
  },
  {
    component: "Grid",
    uuid: uuidv4(),
    animation: "FadeIn",
    children: [
      // {
      //   type: "string",
      //   uuid: uuidv4(),
      //   value: [
      //     image({
      //       uri: "./assets/images/userflow.jpg",
      //       alt: "Black Lion",
      //       caption: "Simplified userflow"
      //     })
      //   ],
      //   component: "InteractiveGrid"
      // },
      {
        type: "string",
        uuid: uuidv4(),
        value: image({
          uri: "./assets/images/userflow.jpg",
          alt: "Black Lion",
          caption: "Simplified userflow"
        }),
        component: "Image"
      }
    ]
  },
  {
    component: "Grid",
    uuid: uuidv4(),
    animation: "FadeIn",
    children: [
      {
        value: "Experiments",
        uuid: uuidv4(),
        component: "H3"
      },
      {
        component: "List",
        uuid: uuidv4(),
        children: [
          {
            type: "string",
            uuid: uuidv4(),
            value: "Use fingertip positions to create a reference plane.",
            component: "ListItem",
            animation: "FadeIn"
          },
          {
            type: "string",
            uuid: uuidv4(),
            value:
              "Reliably detect fingertip position in relation to the reference plane.",
            component: "ListItem",
            animation: "FadeIn"
          },
          {
            type: "string",
            uuid: uuidv4(),
            value: 'Create a "Single-Click" gesture',
            component: "ListItem",
            animation: "FadeIn"
          },
          {
            type: "string",
            uuid: uuidv4(),
            value:
              "Extend gesture suite with Double-Tap, Swipe, Two-Finger-Swipe, Three-Finger-Swipe, Pinch, Pan, Press and Rotate.",
            component: "ListItem",
            animation: "FadeIn"
          }
        ]
      }
    ]
  },
  {
    type: "video",
    uuid: uuidv4(),
    component: "Video",
    animation: "SlideIn",
    value: "./assets/videos/blacklion.mp4"
  },
  {
    component: "Grid",
    uuid: uuidv4(),
    animation: "FadeIn",
    children: [
      {
        uuid: uuidv4(),
        value: [
          image({
            uri: "./assets/images/projects/bl_1.jpg",
            alt: "Some screens."
          }),
          image({
            uri: "./assets/images/projects/bl_3.png",
            alt: "Zoomed in..."
          })
        ],
        component: "Gallery"
      }
    ]
  },
  {
    component: "BlackLion",
    uuid: uuidv4(),
    animation: "FadeIn"
  },
  {
    component: "Grid",
    uuid: uuidv4(),
    animation: "FadeIn",
    children: [
      {
        value: "Achievements",
        uuid: uuidv4(),
        component: "H3"
      },
      {
        component: "List",
        uuid: uuidv4(),
        children: [
          {
            type: "string",
            uuid: uuidv4(),
            value:
              'Presented to Andrew "Boz" Bosworth, VP of Facebook Reality Labs in person',
            component: "ListItem",
            animation: "FadeIn"
          },
          {
            type: "string",
            uuid: uuidv4(),
            value: "US Patent - 16/270,084",
            component: "ListItem",
            animation: "FadeIn"
          }
        ]
      }
    ]
  },
  {
    component: "Grid",
    uuid: uuidv4(),
    animation: "FadeIn",
    children: [
      {
        type: "string",
        uuid: uuidv4(),
        value: image({
          uri: "./assets/images/tesla.jpg",
          alt: "Early Tesla Tests",
          caption: "Early Tesla Tests"
        }),
        component: "Image"
      }
    ]
  },
  {
    component: "Grid",
    uuid: uuidv4(),
    children: [
      {
        type: "string",
        value: "Thanks",
        component: "H2",
        animation: "Grow"
      }
    ]
  },
  {
    component: "Grid",
    uuid: uuidv4(),
    children: [
      {
        type: "string",
        value: "Q&A",
        component: "H1",
        animation: "Grow"
      }
    ]
  },
  {
    component: "Grid",
    uuid: uuidv4(),
    children: [
      {
        type: "string",
        uuid: uuidv4(),
        value: "Appendix",
        component: "H2",
        animation: "Grow"
      }
    ]
  },
  {
    component: "Projects",
    uuid: uuidv4()
  }
];

export default data;
