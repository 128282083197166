import React from "react";
import styled from "styled-components";

import { Fineprint } from "../base";

const StyledFooter = styled.footer`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  box-sizing: border-box;
  padding: 0 4.8rem;
  width: 100%;
  position: absolute;
  bottom: 2.4rem;

  *:nth-child(1) {
    grid-column: 1 / span 2;
  }

  *:nth-child(2) {
    grid-column: 3 / span 2;
  }
  *:nth-child(3) {
    grid-column: 5 / span 2;
  }
  *:nth-child(4) {
    grid-column: 11;
  }
  *:last-child {
    grid-column: 12;
  }
`;

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

const Footer = ({ children }) => {
  const date = new Date();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  return (
    <StyledFooter>
      <Fineprint>Bojan Wilytsch</Fineprint>
      <Fineprint>bwilytsch@gmail.com</Fineprint>
      <Fineprint>+44 7274 99 3753</Fineprint>
      <Fineprint>{month}</Fineprint>
      <Fineprint>{year}</Fineprint>
      {children}
    </StyledFooter>
  );
};

export default Footer;
