const Key = (value, altValue = null, size = 1) => ({
  value,
  altValue,
  size
});

export default {
  R4: [
    Key("`", "~"),
    Key("1", "!"),
    Key("2", "!"),
    Key("3", "!"),
    Key("4", "!"),
    Key("5", "!"),
    Key("6", "!"),
    Key("7", ""),
    Key("8", "!"),
    Key("9", "!"),
    Key("0", "!"),
    Key("-", "!"),
    Key("=", "!"),
    Key("Backspace", null, 2)
  ],
  R3: [
    Key("Tab", null, 1.5),
    Key("q"),
    Key("w"),
    Key("e"),
    Key("r"),
    Key("t"),
    Key("y"),
    Key("u"),
    Key("i"),
    Key("o"),
    Key("p"),
    Key("[", "{"),
    Key("]", "}"),
    Key("\\", "|", 1.5)
  ],
  R2: [
    Key("Capslock", null, 1.75),
    Key("a"),
    Key("s"),
    Key("d"),
    Key("f"),
    Key("g"),
    Key("h"),
    Key("j"),
    Key("k"),
    Key("l"),
    Key(";", ":"),
    Key("'", '"'),
    Key("Enter", null, 2.25)
  ],
  R1: [
    Key("Shift", null, 2.25),
    Key("z"),
    Key("x"),
    Key("c"),
    Key("v"),
    Key("b"),
    Key("n"),
    Key("m"),
    Key(",", "<"),
    Key(".", ">"),
    Key("/", "?"),
    Key("Shift", null, 2.75)
  ],
  R0: [
    Key("Ctrl", null, 1.25),
    Key("Win", null, 1.25),
    Key("Alt", null, 1.25),
    Key(" ", null, 6.25),
    Key("FN", null, 1.25),
    Key("Alt", null, 1.25),
    Key("Wint", null, 1.25),
    Key("Ctrl", null, 1.25)
  ]
};
