import React from "react";
import styled from "styled-components";

const ExtendScreenButton = styled.button`
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 3.2rem;
  /* font-size: 3.2rem; */
  color: white;
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0.72);
  position: absolute;
  opacity: 0.64;
  cursor: pointer;
  transition: opacity ease-in-out 0.12s;

  &:hover {
    opacity: 1;
  }
`;

const ExtensionButtons = styled.div`
  ${ExtendScreenButton}:nth-child(1) {
    top: calc(50% - 2.4rem);
    left: -7.2rem;
  }
  ${ExtendScreenButton}:nth-child(2) {
    left: calc(50% - 2.4rem);
    top: -7.2rem;
  }
  ${ExtendScreenButton}:nth-child(3) {
    top: calc(50% - 2.4rem);
    right: -7.2rem;
  }
`;

const ScreenContainer = styled.div`
  /* width: 75.6rem;
  height: 42rem; */
  position: absolute;
  display: flex;
  top: 0;
  left: 50%;
  transform: translate3D(-50%, -24rem, -4rem) rotateX(0deg);
`;

const Screen = styled.div`
  width: 75.6rem;
  height: 42rem;
  border-radius: 1rem;
  margin: 1.2rem;
  border: 4px solid #111;
  position: relative;
  background: linear-gradient(-45deg, #111, #121212);
`;

const ScreenBackground = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  background-image: url("./assets/images/os/os-bg.jpg");
`;

const Reflection = styled(ScreenBackground)`
  top: calc(100% + 16rem);
  filter: blur(2.4rem);
  box-shadow: 0 -12rem 64rem 6rem rgba(0, 0, 0, 1) inset;
  opacity: 0;
  z-index: -1;
`;

const Statusbar = styled.div`
  width: 100%;
  padding: 0.8rem;
  display: flex;
  justify-content: space-between;
  color: white;
  position: absolute;
  top: 0;
  left: 0%;
  z-index: 2;
`;

const Display = ({ screens, insertScreen }) => {
  return (
    <ScreenContainer>
      {screens.map((screen, idx) => (
        <Screen key={idx}>
          <Reflection className="screen-reflection" />
          <ScreenBackground className="screen-bg" />
          <Statusbar>
            <span>Icongrid.</span>
            <span>Wed, Jull 29 13:43</span>
            <span>Three</span>
          </Statusbar>
          {/* <ExtensionButtons>
            <ExtendScreenButton onClick={insertScreen}>+</ExtendScreenButton>
            <ExtendScreenButton onClick={insertScreen}>+</ExtendScreenButton>
            <ExtendScreenButton onClick={insertScreen}>+</ExtendScreenButton>
          </ExtensionButtons> */}
        </Screen>
      ))}
    </ScreenContainer>
  );
};

export default Display;
