import React, { useEffect, useState } from "react";
import gsap from "gsap";
import styled from "styled-components";

// Components
import { makeDraggable } from "./Draggable";
import Display from "./Display";
import Editor from "./Editor";
import Spotify from "./Spotify";

const Camera = styled.div`
  width: 100%;
  height: 100%;
  perspective: 9999px;
  position: absolute;
  transform: translate3D(0, -8vh, 0);
  opacity: 0;
`;

const Scene = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform-style: preserve-3d;
  transform: translate3D(-50%, -50%, 0) rotateX(-45deg) rotateY(-45deg)
    rotateZ(0deg);
`;

const Renderer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: black;
`;

const DraggableEditor = makeDraggable(Editor);
const DraggableSpotify = makeDraggable(Spotify);

const tl = gsap.timeline({ paused: true });

const BlackLion = () => {
  const [os, updateOS] = useState({
    screens: [1]
  });

  function loadImage(uri) {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.onload = function () {
        resolve(img);
      };

      if (typeof uri !== "string") {
        reject("Not a valid image uri.");
        return;
      }

      img.src = uri;
    });
  }

  async function fetchData(cb = () => {}) {
    const images = [
      "./assets/images/os/album-art.jpg",
      "./assets/images/os/os-bg.jpg"
    ];

    await Promise.all(images.map(loadImage));

    cb();
  }

  useEffect(() => {
    fetchData(() => {
      // Animation
      tl.to("#camera", { opacity: 1, duration: 0.24 }, "start");
      tl.to(
        ".screen-bg",
        { opacity: 1, duration: 0.32, delay: 0.24 },
        "turnScreenOn"
      );
      tl.to(
        ".screen-reflection",
        { opacity: 0.48, duration: 0.32, delay: 0.24 },
        "turnScreenOn"
      );
      tl.to(
        ".widget",
        { opacity: 1, stagger: 0.24, duration: 0.48, z: "+=4" },
        "showWidgets"
      );
      // tl.to( "#keyboard",
      //   { opacity: 1, duration: 0.64, z: "+=6" },
      //   "showKeyboard"
      // );

      tl.play();
    });

    return () => {
      tl.clear();
    };
  }, []);

  const insertScreen = (position) => {
    updateOS((state) => {
      return { ...state, screens: [...state.screens, "test"] };
    });
  };

  const { screens } = os;

  return (
    <Renderer>
      <Camera id="camera">
        <Scene>
          <Display screens={screens} insertScreen={insertScreen} />
          <DraggableSpotify x="80" y="120" z="28" className="widget" />
          <DraggableEditor x="-320" y="-120" z="28" className="widget" />
        </Scene>
      </Camera>
      {/* <input
        style={{ position: "relative", zIndex: 999 }}
        type="range"
        min="0"
        max="1"
        step="0.01"
        onChange={(e) => {
          tl.progress(e.target.value);
        }}
        defaultValue="0"
      /> */}
    </Renderer>
  );
};

export default BlackLion;
