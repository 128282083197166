import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import gsap from "gsap";

export function loadImage(image) {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = function () {
      resolve(img);
    };

    img.onerror = function (err) {
      reject(err);
    };

    if (typeof image.uri !== "string") {
      reject("Not a valid image uri.");
      return;
    }

    img.src = image.uri;
  });
}

export async function getImages(imageArr) {
  if (!Array.isArray(imageArr)) return [];

  const promises = imageArr.map(loadImage);

  return Promise.all(promises);
}

const ImageContainer = styled.div`
  background-color: var(--bg-transparent-light);
  display: block;
  width: 100%;
  opacity: 0;
`;

const ImageFigure = styled.figure`
  position: relative;
  opacity: 0;
`;

const ImageCaption = styled.figcaption`
  text-align: center;
  padding: 1.2rem;
  width: 100%;
  font-size: 1rem;
  color: var(--txt-light);
  position: absolute;
`;

const PreviewImage = styled.img`
  width: 100%;
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

const LazyLoadImage = ({ value, children, ...props }) => {
  const [image, setImage] = useState({ uri: "", alt: "" });
  const imageRef = useRef(null);
  const containerRef = useRef(null);
  const animations = useRef([]);

  async function fetchImageFromData(imageData) {
    await loadImage(imageData);

    setImage(imageData);

    // Animation
  }

  useEffect(() => {
    fetchImageFromData(value || children);
    return () => {
      animations.current.forEach((a) => a.kill());
    };
  }, []);

  useEffect(() => {
    if (image.uri.length === 0) return;

    const a1 = gsap.to(containerRef.current, {
      opacity: 1,
      duration: 0.24,
      delay: 0.24
    });
    const a2 = gsap.to(imageRef.current, {
      opacity: 1,
      duration: 1,
      delay: 0.64
    });

    animations.current = [a1, a2];
  }, [image]);

  const { uri, alt, caption } = image;

  return (
    <ImageContainer {...props} ref={containerRef}>
      <ImageFigure ref={imageRef}>
        <PreviewImage src={uri} alt={alt} />
        {caption && <ImageCaption>{caption}</ImageCaption>}
      </ImageFigure>
    </ImageContainer>
  );
};

export default LazyLoadImage;
