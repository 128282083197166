import React, { useState, useEffect } from "react";
import styled from "styled-components";
import gsap from "gsap";
import { getImages } from "../base/Image";
import { Fineprint } from "../base";

async function fetchImages(data, cb = () => {}) {
  const images = await getImages(data);

  cb(images);
}

const GalleryButton = styled.button`
  background: transparent;
  border: none;
  height: 100%;
  width: 50%;
  text-indent: -9999px;
  outline: none;
  cursor: pointer;
`;

const GalleryImage = ({ image }) => {
  useEffect(() => {
    if (!image) return;
    const animation = gsap.to("#active-image", {
      opacity: 1,
      duration: 0.32,
      delay: 0.32
    });

    return () => {
      animation.kill();
    };
  });

  if (!image) return null;

  const { uri, alt } = image;

  return <img src={uri} alt={alt} id="active-image" />;
};

const GalleryNextButton = styled(GalleryButton)``;
const GalleryPrevButton = styled(GalleryButton)``;

const GalleryActions = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;

const StyledGallery = styled.div`
  max-width: 192rem;
  width: calc(100vw - 12.8rem);
  padding-bottom: 56.25%;
  background-color: var(--bg-transparent-light);
  position: relative;
  opacity: 0;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`;

const Pagination = styled(Fineprint)`
  width: 100%;
  text-align: center;
  padding: 2.4rem;
`;

const Gallery = ({ images, children, ...props }) => {
  const [imageIndex, setImageIndex] = useState(0);
  const [collection, setCollection] = useState([]);

  const next = () => {
    if (collection.length < 2) return;

    gsap.to("#active-image", {
      opacity: 0,
      duration: 0.16,
      onComplete: () => {
        setImageIndex((state) => {
          const nextState = (state + 1) % collection.length;

          return nextState;
        });
      }
    });
  };

  const previous = () => {
    if (collection.length < 2) return;

    gsap.to("#active-image", {
      opacity: 0,
      duration: 0.16,
      onComplete: () => {
        setImageIndex((state) => {
          const nextState = state === 0 ? collection.length - 1 : state - 1;

          return nextState;
        });
      }
    });
  };

  const handleKeydown = (e) => {
    switch (e.key) {
      case "ArrowUp":
        previous();
        break;
      case "ArrowDown":
        next();
        break;
      default:
      // Do nothing
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeydown);

    return () => {
      window.removeEventListener("keydown", handleKeydown);
    };
  });

  useEffect(() => {
    const imageArr = images || children;
    setCollection(imageArr);
    fetchImages(imageArr, () => {
      gsap.to("#gallery", { opacity: 1 });
    });
  }, []);

  if (!collection || collection.length === 0) return null;

  const currentImage = collection[imageIndex];

  return (
    <>
      <StyledGallery {...props} id="gallery">
        <GalleryImage image={currentImage} />
        <GalleryActions>
          <GalleryPrevButton onClick={previous}>Previous</GalleryPrevButton>
          <GalleryNextButton onClick={next}>Next</GalleryNextButton>
        </GalleryActions>
      </StyledGallery>
      <Pagination>
        {imageIndex + 1} / {collection.length}
      </Pagination>
      {/* <Caption>
        {currentImage.alt.length > 0 && <caption>{currentImage.alt}</caption>}
      </Caption> */}
    </>
  );
};

export default Gallery;
