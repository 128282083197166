import React from "react";
import { Helmet } from "react-helmet";

const Header = () => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>Project:Eden</title>
      <link rel="canonical" href="https://eden.madebybojan.com" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  );
};

export default Header;
