import React, { useState, forwardRef } from "react";
import styled from "styled-components";
import gsap from "gsap";

import Widget from "./Widget";
import Keyboard from "./Keyboard";

const StyledEditor = styled(Widget)`
  box-sizing: border-box;
  font-family: var(--font-accent);
  color: #010101;
  width: 32rem;
  height: 24rem;
  padding: 2.4rem;
  background: linear-gradient(-45deg, #efefef, #fff);
  position: absolute;
  border-radius: 1rem;
  top: 50%;
  left: 50%;
  /* transform: translate3D(-100%, -42%, 3rem); */
  box-shadow: 2.4rem 1.2rem 4.8rem 2.4rem rgba(0, 0, 0, 0.24);
`;

const EditorReflection = styled(StyledEditor)`
  left: 0;
  top: 48rem;
  filter: blur(24px);
  box-shadow: 0 -12rem 64rem 6rem rgba(0, 0, 0, 1) inset;
  opacity: 1;
`;

// Widgets
const EditorTextarea = styled.textarea`
  width: 100%;
  height: 100%;
  font-size: 0.8rem;
  background: transparent;
  outline: none;
  border: none;
  caret-color: var(--color-accent-r);
  position: relative;
`;

const EditorFooter = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 1.2rem;
  font-size: 0.8rem;
  color: #ccc;
`;

const getWordCount = (string) => {
  return string.split(" ").filter((str) => str.length > 0).length;
};

const getCharacterCount = (string) => {
  return string.replace(" ", "").split("").length;
};

const Editor = forwardRef(({ children, ...props }, ref) => {
  const [editor, updateEditor] = useState({ value: "" });

  const showKeyboard = (e) => {
    gsap.to("#keyboard", { opacity: 1, duration: 0.32, z: "+=6" });
  };

  const hideKeyboard = (e) => {
    gsap.to("#keyboard", { opacity: 0, duration: 0.16, z: "-=6" });
  };

  const wordCount = getWordCount(editor.value);
  const characterCount = getCharacterCount(editor.value);
  return (
    <>
      <Keyboard id="keyboard" />
      <StyledEditor {...props} ref={ref}>
        <EditorTextarea
          onFocus={showKeyboard}
          onBlur={hideKeyboard}
          onChange={(e) => {
            e.stopPropagation();
            e.preventDefault();
            const { value } = e.target;
            updateEditor((state) => {
              return { ...state, value };
            });
          }}
          value={editor.value}
        ></EditorTextarea>
        <EditorFooter>
          {wordCount} words • {characterCount} characters
        </EditorFooter>
        {children}
      </StyledEditor>
    </>
  );
});

export default Editor;
