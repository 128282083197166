import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import gsap from "gsap";

import { ReactComponent as IcnMaximize } from "../icons/icn_maximize.svg";
import { ReactComponent as IcnMinimize } from "../icons/icn_minimize.svg";

import { Body } from "../base";

import { AppContext, generalActions } from "../App";

const MenuWrapper = styled.nav`
  width: calc(100vw - 6.4rem);
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 3.2rem;
  top: 2.4rem;
  z-index: 12;
`;

const StyledHelp = styled.div`
  width: 42rem;
  position: fixed;
  left: 3.2rem;
  top: 8.8rem;
  color: white;
  /* box-shadow: 0 2.4rem 4.8rem 1rem rgba(0, 0, 0, 0.24); */
`;

const Shortcut = styled.span`
  font-size: 1.2rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.6rem;
  display: inline-flex;
  margin: 0 0.4rem;
  width: 3.2rem;
  height: 3.2rem;
  border: 1px solid rgba(255, 255, 255, 0.12);
  /* margin: 0 1.2rem 0 0; */
`;

const ShortcutLong = styled(Shortcut)`
  /* margin: 0; */
  width: 4.2rem;
`;

const ShortcutItem = styled.div`
  margin: 0 0 1.2rem 0;
`;

const ShortcutLabel = styled.span`
  margin: 0 0 0 0.8rem;
`;

const CurrentDate = styled.div`
  margin: 0.8rem 0 0.32rem 0;
`;

const Module = styled.div`
  box-sizing: border-box;
  font-size: 1.4rem;
  padding: 2.4rem;
  background: #111;
  border-radius: 2rem;
  margin: 0 0 1.2rem 0;

  > *:last-child {
    margin: 0;
  }
`;

const blockEvent = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

const Clock = () => {
  const [currentTime, setCurrentTime] = useState("");
  const [currentDate, setCurrentDate] = useState("");

  const getTime = () => {
    const date = new Date();
    const localTime = date.toLocaleTimeString("en-US");
    return localTime;
  };

  const getDate = () => {
    const date = new Date().toLocaleDateString("en-US");
    return date;
  };

  useEffect(() => {
    setCurrentTime(getTime());
    setCurrentDate(getDate());
    const interval = setInterval(() => {
      setCurrentTime(getTime());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <>
      <CurrentDate>{currentDate}</CurrentDate>
      <Body>{currentTime}</Body>
    </>
  );
};

const Help = ({ handleClose }) => {
  useEffect(() => {
    window.addEventListener("click", handleClose);

    return () => {
      window.removeEventListener("click", handleClose);
    };
  }, []);

  return (
    <StyledHelp onClick={blockEvent}>
      <Module>
        <ShortcutItem>
          <ShortcutLong>Ctrl</ShortcutLong> + <Shortcut>M</Shortcut>{" "}
          <ShortcutLabel>Menu on/off</ShortcutLabel>
        </ShortcutItem>
        <ShortcutItem>
          <ShortcutLong>Ctrl</ShortcutLong> + <Shortcut>B</Shortcut>{" "}
          <ShortcutLabel>Mode dark/light</ShortcutLabel>
        </ShortcutItem>
        <ShortcutItem>
          <ShortcutLong>Ctrl</ShortcutLong> + <Shortcut>F</Shortcut>{" "}
          <ShortcutLabel>Fullscreen on/off</ShortcutLabel>
        </ShortcutItem>
        <ShortcutItem>
          <Shortcut>←</Shortcut> <ShortcutLabel>Previous Slide</ShortcutLabel>
        </ShortcutItem>
        <ShortcutItem>
          <Shortcut>→</Shortcut> or <ShortcutLong>Space</ShortcutLong>{" "}
          <ShortcutLabel>Next Slide</ShortcutLabel>
        </ShortcutItem>
      </Module>
      <Module>
        <Clock />
      </Module>
    </StyledHelp>
  );
};

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 2.8rem;
  padding: 0;
  margin: 0;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  /* border: 1px solid var(--txt-light); */
  cursor: pointer;
  transition: background-color ease-in-out 0.16s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.06);
  }
`;

const IcnMenu = styled.div`
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  position: relative;

  &::after {
    content: "";
    display: block;
    width: 2.4rem;
    height: 0.2rem;
    border-radius: 0.1rem;
    background-color: var(--txt-light);
    transform: translate(-50%, -0.3rem);
    position: absolute;
    left: 50%;
    top: 50%;
    transition: all ease-in-out 0.16s;
  }

  &::before {
    content: "";
    display: block;
    width: 2.4rem;
    height: 0.2rem;
    border-radius: 0.1rem;
    background-color: var(--txt-light);
    transform: translate(-50%, 0.3rem);
    position: absolute;
    left: 50%;
    top: 50%;
    transition: all ease-in-out 0.16s;
  }

  &.open {
    &::after {
      content: "";
      display: block;
      width: 2.4rem;
      height: 0.2rem;
      border-radius: 0.1rem;
      background-color: var(--txt-light);
      transform: translate(-50%) rotate(-45deg);
      position: absolute;
      left: 50%;
      top: 50%;
    }

    &::before {
      content: "";
      display: block;
      width: 2.4rem;
      height: 0.2rem;
      border-radius: 0.1rem;
      background-color: var(--txt-light);
      transform: translate(-50%) rotate(45deg);
      position: absolute;
      left: 50%;
      top: 50%;
    }
  }
`;

// const IcnClose = styled.div`
//   display: block;
//   width: 2.4rem;
//   height: 2.4rem;
//   position: relative;

//   &::after {
//     content: "";
//     display: block;
//     width: 2.4rem;
//     height: 0.2rem;
//     border-radius: 0.1rem;
//     background-color: var(--txt-light);
//     transform: translate(-50%, -50%) rotate(45deg);
//     position: absolute;
//     left: 50%;
//     top: 50%;
//   }

//   &::before {
//     content: "";
//     display: block;
//     width: 2.4rem;
//     height: 0.2rem;
//     border-radius: 0.1rem;
//     background-color: var(--txt-light);
//     transform: translate(-50%, -50%) rotate(-45deg);
//     position: absolute;
//     left: 50%;
//     top: 50%;
//   }
// `;

const Menu = () => {
  const { dispatch } = useContext(AppContext);
  const [isFullscreen, setFullscreen] = useState(false);
  const [showHelp, setShowHelp] = useState(false);

  async function openFullscreen() {
    const elem = document.documentElement;

    if (!elem) return;

    try {
      if (elem.requestFullscreen) {
        await elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        await elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        await elem.msRequestFullscreen();
      }
      setFullscreen(true);
    } catch (err) {
      console.log(err);
    }
  }

  /* Close fullscreen */
  function closeFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
    setFullscreen(false);
  }

  function toggleFullscreen() {
    const isFullscreen = document.fullscreen;

    if (isFullscreen) {
      closeFullscreen();
    } else {
      openFullscreen();
    }
  }

  const toggleTheme = () => {
    dispatch({ type: generalActions.TOGGLETHEME });
  };

  const toggleHelp = () => {
    setShowHelp((state) => !state);
  };

  const closeHelp = () => {
    setShowHelp(false);
  };

  const handleKeydown = (e) => {
    // blockEvent(e);

    if (e.ctrlKey && e.key === "m") {
      toggleHelp();
      return;
    }

    if (e.ctrlKey && e.key === "f") {
      toggleFullscreen();
      return;
    }

    if (e.ctrlKey && e.key === "b") {
      toggleTheme();
      return;
    }

    closeHelp();
  };

  // On Mount
  useEffect(() => {
    let requestDarkMode = window.matchMedia("(prefers-color-scheme: dark)")
      .matches;

    if (requestDarkMode) {
      dispatch({ type: generalActions.CHANGETHEME, data: "dark" });
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleKeydown);

    return () => {
      window.removeEventListener("keydown", handleKeydown);
    };
  });

  return (
    <MenuWrapper>
      <Button onClick={toggleHelp}>
        <IcnMenu className={showHelp ? "open" : null} />
      </Button>
      <Button onClick={toggleFullscreen}>
        {isFullscreen ? <IcnMinimize /> : <IcnMaximize />}
      </Button>
      {showHelp && <Help handleClose={closeHelp} />}
    </MenuWrapper>
  );
};

export default Menu;
